











































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import ImageInput from "./ImageInput.vue";
import moment from "moment";

export default Vue.extend({
    components: {
        ImageInput
    },
    data() {
        return {
            util,
            userManager,
            dialog: false,
            snackbar: false,
            currentImages: [],
            currentImagesExemplaryBusiness: [],
            currentImagesBreakthroughBusiness: [],
            fails: "",
            id: 0,
            idExemplaryBusiness: 0,
            idBreakthroughBusiness: 0,
            name: "",
            nameExemplaryBusiness: "",
            nameBreakthroughBusiness: "",
            sales: "",
            salesExemplaryBusiness: "",
            salesBreakthroughBusiness: "",
            form: false,
            valid: false,
            menu: false,
            date: moment().format("YYYY-MM"),
            dialogType: false,
            formType: false,
            formBreakthroughBusiness: false,
            formExemplaryBusiness: false,
            hideCreate: false,
            loadingCreate: false,
            dialogDelete: false,
            ruleRequired: (value) => !!value || this.$t("REQUIRED"),
            ruleRequiredArray: (value) =>
                !!(Array.isArray(value) && value.length > 0) || this.$t("REQUIRED"),
            options: {},
            loading: false,
            dataCount: 0,
            editedIndex: -1,
            editedSales: -1,
            editedItem: {},
            allowDelete: {},
            users: [],
            peakSales: {},
            exemplaryBusiness: {},
            breakthroughBusiness: {},
            userInfo: userManager.getUserInfo(),
            picker: "",
            urlFunc(item) {
                return Vue.filter("discussionMessageImageUrl")(item);
            },
        };
    },
    watch: {
        options: {
            handler() {
                this.initialize();
            },
            deep: true,
        },
    },
    methods: {
        async initialize() {
            const filters = [];
            this.resetForm()
            const search = {date: this.date}
            this.peakSales = {}
            this.exemplaryBusiness = {}
            this.breakthroughBusiness = {}
            for (const searchKey in search) {
                if (search[searchKey]) {
                    if (searchKey === "id") {
                        search[searchKey] = search[searchKey].replace("HD", "");
                    }
                    filters.push({
                        key: searchKey,
                        operator: "=",
                        value: search[searchKey],
                    });
                }
            }
            this.loading = true;
            const {items} = await apiClient.salesGetAll({
                options: this.options,
                filters,
            });
            this.peakSales = items.find(item => item.type === "peakSales")
            this.exemplaryBusiness = items.find(item => item.type === "exemplaryBusiness")
            this.breakthroughBusiness = items.find(item => item.type === "breakthroughBusiness")
            this.getPeakSales(this.peakSales)
            this.getExemplaryBusiness(this.exemplaryBusiness)
            this.getBreakthroughBusiness(this.breakthroughBusiness)
            this.loading = false;
        },
        getPeakSales(item) {
            if (item) {
                this.id = item.id
                this.name = item.name
                this.sales = item.sales
                this.currentImages = item.images
            }
        },
        getExemplaryBusiness(item) {
            if (item) {
                this.idExemplaryBusiness = item.id
                this.nameExemplaryBusiness = item.name
                this.salesExemplaryBusiness = item.sales
                this.currentImagesExemplaryBusiness = item.images
            }
        },
        getBreakthroughBusiness(item) {
            if (item) {
                this.idBreakthroughBusiness = item.id
                this.nameBreakthroughBusiness = item.name
                this.salesBreakthroughBusiness = item.sales
                this.currentImagesBreakthroughBusiness = item.images
            }
        },
        resetForm() {
            this.idBreakthroughBusiness = 0
            this.nameBreakthroughBusiness = null
            this.salesBreakthroughBusiness = null
            this.currentImagesBreakthroughBusiness = null
            this.idExemplaryBusiness = 0
            this.nameExemplaryBusiness = null
            this.salesExemplaryBusiness = null
            this.currentImagesExemplaryBusiness = null
            this.id = 0
            this.name = null
            this.sales = null
            this.currentImages = null
        },
        editItem() {
            this.peakSales = undefined
        },
        editItemExemplaryBusiness() {
            this.exemplaryBusiness = undefined
        },
        editItemBreakthroughBusiness() {
            this.breakthroughBusiness = undefined
        },
        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            this.closeDelete();
            await apiClient.processGuiderDelete(this.editedItem);
            await this.initialize();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save() {
            let result;
            const userInfo = {...this.userManager.getUserInfo()};
            const params = {
                images: this.currentImages,
                name: this.name,
                sales: this.sales,
                id: this.id,
                type: "peakSales",
                date: this.date,
                created_by: userInfo.sub
            }
            if (this.id !== 0) {
                result = await apiClient.salesUpdate(params);
                await this.initialize()
            } else {
                result = await apiClient.salesCreate(params);
            }
            if (!result.errors) {
                this.close();
                await this.initialize();
            } else {
                this.snackbar = true;
                this.fails = result.message;
            }
        },
        async saveExemplaryBusiness() {
            let result;
            const userInfo = {...this.userManager.getUserInfo()};
            const params = {
                images: this.currentImagesExemplaryBusiness,
                name: this.nameExemplaryBusiness,
                sales: this.salesExemplaryBusiness,
                id: this.idExemplaryBusiness,
                type: "exemplaryBusiness",
                date: this.date,
                created_by: userInfo.sub
            }
            if (this.idExemplaryBusiness !== 0) {
                result = await apiClient.salesUpdate(params);
                await this.initialize()
            } else {
                result = await apiClient.salesCreate(params);
            }
            if (!result.errors) {
                this.close();
                await this.initialize();
            } else {
                this.snackbar = true;
                this.fails = result.message;
            }
        },
        async saveBreakthroughBusiness() {
            let result;
            const userInfo = {...this.userManager.getUserInfo()};
            const params = {
                images: this.currentImagesBreakthroughBusiness,
                name: this.nameBreakthroughBusiness,
                sales: this.salesBreakthroughBusiness,
                id: this.idBreakthroughBusiness,
                date: this.date,
                type: "breakthroughBusiness",
                created_by: userInfo.sub
            }
            if (this.idBreakthroughBusiness !== 0) {
                result = await apiClient.salesUpdate(params);
                await this.initialize()
            } else {
                result = await apiClient.salesCreate(params);
            }
            if (!result.errors) {
                this.close();
                await this.initialize();
            } else {
                this.snackbar = true;
                this.fails = result.message;
            }
        },
        getName(value, data, keyData = "id", keyName = "name") {
            if (value) {
                if (Array.isArray(data)) {
                    const length = data.length;
                    for (let i = 0; i < length; i++) {
                        if (data[i][keyData] === value) {
                            return data[i][keyName];
                        }
                    }
                }
                return value;
            }
            return "";
        },
    },
    created() {
        if (!userManager.checkRole(["kd", "gd",  "t"])) {
            this.$router.push({name: 'notification'})
        }
        this.initialize()
    },
});
